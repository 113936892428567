<template>
  <div class="facture-sci-mensuel">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="fixDate"
          @click.stop="openModal"
        >
          <IconAddFacture></IconAddFacture>
        </div>
      </template>
      <span>Ajouter Facture</span>
    </v-tooltip>
    <!--MODAL CREATION FACTURE-->
    <v-dialog
      v-model="dialog"
      max-width="900px"
      scrollable
      :persistent="true"
      :hide-overlay="true"
      content-class="custom-vuetify-dialog-header"
    >
      <v-card class="custom-dialog">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Nouvelle facture</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card">
          <v-form>
            <v-row class="row-top">
              <v-col cols xs="6" sm="6" md="6">
                <v-select
                  :items="ListYear"
                  v-model="filterYear"
                  label="Année"
                  dense
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col cols xs="6" sm="6" md="6">
                <v-select
                  :items="computedGetMonthForYearTh"
                  v-model="filterMonth"
                  label="Mois"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  :rules="requiredRule"
                  required
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="flex-row-radio-sci-mensuel">
              <v-col cols="4">
                Type de document
              </v-col>
              <v-col cols xs="5" sm="5" md="5">
                <v-radio-group v-model="checked" row>
                  <v-radio
                    v-for="(n, index) in ListTypeDoc"
                    :key="index"
                    :label="n.text"
                    :value="n.value"
                    class="radio-btn"
                    color="#704ad1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="row-flex">
              <v-col cols xs="10" sm="10" md="10">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="vendeurs"
                  :items="usersMensuel"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Société"
                  item-text="name"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :small-chips="true"
                  :clearable="true"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                ></v-autocomplete>
              </v-col>
              <v-col cols xs="2" sm="2" md="2">
                <v-checkbox
                  v-model="checkAll"
                  label="Tous"
                  color="#704ad1"
                  class="mt-1"
                  @change="selectAllSociete"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="row-flex">
              <v-col cols xs="10" sm="10" md="10">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="clients"
                  :items="computedGetSettingFilialesTh"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Client"
                  item-text="name"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :small-chips="true"
                  :clearable="true"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun Client trouvée"
                ></v-autocomplete>
              </v-col>
              <v-col cols xs="2" sm="2" md="2">
                <v-checkbox
                  v-model="checkAllClient"
                  label="Tous"
                  class="mt-1"
                  color="#704ad1"
                  @change="selectAllClient"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitNewFacture"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="closeModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--REGENERATE PROFORMA -->
    <v-dialog
      v-model="proformatModal"
      max-width="1200px"
      :persistent="true"
      :hide-overlay="true"
      scrollable
      content-class="custom-vuetify-dialog-proforma-modal"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Êtes-vous sûr de vouloir régénérer ses factures proforma ?</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="closeModalProforma()"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-data-table
            :headers="fields"
            :items="listProformatAlredyExiste"
            hide-default-footer
            hide-action
            disable-filtering
            disable-pagination
            class="table-fac-sci-mensuel-proforma"
            item-class="text-center"
          >
            <template v-slot:[`header.check_all`]="{ header }">
              <td class="custom-cell">
                <div v-if="header.value === 'check_all'">
                  <v-checkbox
                    class="checked-global"
                    v-model="checkAllfacturesProformat"
                    @change="checkAllFunction"
                    color="#704ad1"
                    :value="true"
                    :unchecked-value="false"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
            </template>
            <template v-slot:[`item.check_all`]="{ item }">
              <td class="custom-cell">
                <v-checkbox
                  v-model="item.check"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  hide-details
                ></v-checkbox>
              </td>
            </template>
            <template v-slot:[`item.societe`]="{ item }">
              <td class="custom-cell">
                {{ item.vendeur }}
              </td>
            </template>
            <template v-slot:[`item.client`]="{ item }">
              <td class="custom-cell">
                {{ item.client }}
              </td>
            </template>
            <template v-slot:[`item.num-facture`]="{ item }">
              <td class="custom-cell">
                {{ item.numero }}
              </td>
            </template>
            <template v-slot:[`item.montant`]="{ item }">
              <td class="custom-cell">{{ item.montant }} €</td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click.prevent.stop="regenerateFacturs"
            :loading="updateProformatLoding"
            :disabled="computedChekedProformat.length == 0"
            :class="{ loader: updateProformatLoding }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('proformatModal')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--RAPPORT CREATION FACTURE-->
    <v-dialog
      v-model="errorModal"
      max-width="1800px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Rapport Création Facture(s)</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('errorModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-row class="mt-4">
            <v-col cols="12" v-if="listFactureSuccee.length">
              <div class="titleFiche">
                Factures ajouté avec succès ({{ listFactureSuccee.length }}):
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        {{ type_produit == 'bien' ? 'Bien' : 'Service' }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in listFactureSuccee" :key="index">
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.montant }} €</td>
                      <td class="text-center">{{ item.service_bien }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="listFacturesExiste.length">
              <div class="titleFiche">
                Factures existe déja ({{ listFacturesExiste.length }}) :
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        {{ type_produit == 'bien' ? 'Bien' : 'Service' }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listFacturesExiste"
                      :key="index"
                    >
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.numero }}</td>
                      <td class="text-center">{{ item.montant }} €</td>
                      <td class="text-center">{{ item.service_bien }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" v-if="listFactureUpdated.length">
              <div class="titleFiche">
                Proforma régénérée avec succès ({{
                  listFactureUpdated.length
                }}):
              </div>
              <v-simple-table class="table-master mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center dark-class">Société</th>
                      <th class="text-center dark-class">Client</th>
                      <th class="text-center dark-class">Numéro Facure</th>
                      <th class="text-center dark-class">Montant</th>
                      <th class="text-center dark-class">
                        {{ type_produit == 'bien' ? 'Bien' : 'Service' }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in listFactureUpdated"
                      :key="index"
                    >
                      <td class="text-center">{{ item.vendeur }}</td>
                      <td class="text-center">{{ item.client }}</td>
                      <td class="text-center">{{ item.num }}</td>
                      <td class="text-center">{{ item.montant }} €</td>
                      <td class="text-center">{{ item.service_bien }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" v-if="listSocieteErrorRib.length">
              <div class="titleFiche">
                Liste des sociétés qui n'ont pas de RIB ({{
                  listSocieteErrorRib.length
                }}):
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title
                      class="title-list-sci-mensuel"
                      v-for="(item, index) in listSocieteErrorRib"
                      v-bind:key="index"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col v-if="listSocieteError.length" cols="6">
              <div class="titleFiche">
                Liste des sociétés qui ont des erreurs de séquence ({{
                  listSocieteError.length
                }}):
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title
                      class="title-list-sci-mensuel"
                      v-for="(item, index) in listSocieteError"
                      v-bind:key="index"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" v-if="vendeurs_without_configs.length">
              <div class="titleFiche">
                Société n'a pas de configuration ({{
                  vendeurs_without_configs.length
                }}):
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="content-list-groupe">
                    <v-list-item-title
                      class="title-list-sci-mensuel"
                      v-for="(item, index) in vendeurs_without_configs"
                      v-bind:key="index"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn outlined @click.prevent="hideModal('errorModal')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    type_produit: { required: true },
    hiddenContent: { required: false }
  },
  data() {
    return {
      dialog: false,
      requiredRule: [v => !!v || 'Cette sélection est requise.'],
      ListTypeDoc: [
        { text: 'Facture', value: 'facture' },
        { text: 'Proforma', value: 'proforma' }
      ],
      fields: [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important'
        },
        {
          value: 'societe',
          text: 'Societe',
          sortable: true
        },
        {
          value: 'client',
          text: 'client',
          sortable: true
        },
        {
          value: 'num-facture',
          text: 'Num Facture',
          sortable: true
        },
        {
          value: 'montant',
          text: 'montant',
          sortable: true
        }
      ],
      checkAllfacturesProformat: false,
      listFacturesExiste: [],
      listFactureSuccee: [],
      listSocieteError: [],
      listSocieteErrorRib: [],
      vendeurs_without_configs: [],
      listProformatAlredyExiste: [],
      listFactureUpdated: [],
      factursToRegenerate: [],
      vendeurs: [],
      clients: [],
      checked: 'proforma',
      error: null,
      loading: false,
      filterMonth: null,
      filterYear: null,
      ListYear: [],
      checkAll: false,
      checkAllClient: false,
      updateProformatLoding: false,
      errorModal: false,
      proformatModal: false
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'usersMensuel',
      'getSettingFilialesTh'
    ]),
    computedGetSettingFilialesTh() {
      return this.getSettingFilialesTh.filter(
        item => item.type != 'particulier' && item.type != 'passage'
      );
    },
    computedGetMonthForYearTh() {
      return [
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Aôut', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 }
      ];
    },
    computedChekedProformat() {
      let computedProformatToRegenerate = [];
      this.listProformatAlredyExiste.map(item => {
        if (item.check === true) {
          computedProformatToRegenerate.push(item);
        }
      });
      return computedProformatToRegenerate;
    }
  },
  methods: {
    ...mapActions([
      'getFilialeByUser',
      'fetchMonthForYearTH',
      'addFactureMensuel',
      'getClientOnligneUsers',
      'regenerateProformat',
      'getSettingFilialeTh'
    ]),
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
      this.checkAll = false;
      this.checkAllClient = false;
      this.vendeurs = [];
      this.clients = [];
      this.updateProformatLoding = false;
      this.error = null;
      this.loading = false;
      this.fixDate();
      this.checked = 'proforma';
    },
    selectAllSociete() {
      this.vendeurs = [];
      if (this.checkAll == true) {
        this.usersMensuel.map(societe => {
          this.vendeurs.push(societe);
        });
      } else {
        this.vendeurs = [];
      }
    },
    checkAllFunction() {
      if (
        this.listProformatAlredyExiste &&
        this.listProformatAlredyExiste.length
      ) {
        this.listProformatAlredyExiste.map(
          item => (item.check = this.checkAllfacturesProformat)
        );
      }
    },
    selectAllClient() {
      this.clients = [];
      if (this.checkAllClient == true) {
        this.computedGetSettingFilialesTh.map(client => {
          this.clients.push(client);
        });
      } else {
        this.clients = [];
      }
    },
    resetModal() {
      this.updateProformatLoding = false;
      this.error = null;
      this.loading = false;
      this.fixDate();
      this.checked = 'proforma';
    },
    closeModalProforma() {
      this.hideModal('proformatModal');
      this.showModal('errorModal');
    },
    hideModal(ref) {
      this[ref] = false;
      this.$emit('reloadData');
      this.resetModal();
    },
    showModal(ref) {
      this[ref] = true;
    },
    fixDate() {
      var courantYear = moment().year();
      var courantMonthId = moment().month();
      this.filterYear = courantYear;
      this.filterMonth = courantMonthId + 1;
      for (var i = 2019; i <= courantYear + 2; i++) {
        this.ListYear.push({ value: i, text: i });
      }
    },
    async regenerateFacturs() {
      this.updateProformatLoding = true;
      let bodyFormData = new FormData();
      for (let i = 0; i < this.computedChekedProformat?.length; i++) {
        bodyFormData.append(
          'ids[' + i + ']',
          this.computedChekedProformat[i].id
        );
      }
      const response = await this.regenerateProformat(bodyFormData);
      if (response.succes) {
        this.updateProformatLoding = false;
        this.listFactureUpdated = response.message.success;
        this.hideModal('proformatModal');
        this.showModal('errorModal');
      } else {
        this.updateProformatLoding = false;
      }
    },
    async handleSubmitNewFacture() {
      this.error = null;
      this.loading = true;
      let bodyFormData = new FormData();
      bodyFormData.append('annee', this.filterYear);
      bodyFormData.append('mois', this.filterMonth);
      bodyFormData.append('type', this.checked);
      bodyFormData.append('type_produit', this.type_produit);
      for (let i = 0; i < this.vendeurs.length; i++) {
        bodyFormData.append('vendeur_ids[' + i + ']', this.vendeurs[i].id);
      }
      for (let i = 0; i < this.clients.length; i++) {
        bodyFormData.append('acheteur_ids[' + i + ']', this.clients[i].id);
      }
      const response = await this.addFactureMensuel(bodyFormData);

      if (response.succes) {
        this.error = '';
        this.loading = false;
        this.listFacturesExiste = response.message.already_exist;
        this.listFactureSuccee = response.message.success;
        this.listSocieteError = response.message.error_seq.filter(
          item => item != null
        );
        this.listSocieteErrorRib = response.message.error_rib.filter(
          item => item != null
        );
        this.listProformatAlredyExiste = response.message.proforma_already_exist.map(
          item => {
            return { ...item, check: false };
          }
        );
        this.vendeurs_without_configs = response.message.vendeurs_without_configs.filter(
          item => item != null
        );
        if (
          response?.message?.proforma_already_exist?.length > 0 &&
          this.checked === 'proforma'
        ) {
          this.hideModal('dialog');
          this.showModal('proformatModal');
        } else {
          this.hideModal('dialog');
          this.showModal('errorModal');
        }
        this.hideModal('dialog');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    }
  },
  components: {
    IconAddFacture: () => import('@/assets/img/IconAddFacture.vue')
  },
  async mounted() {
    var courantMonthId = moment().month();
    var courantYear = moment().year();
    this.year = courantYear;
    await this.fetchMonthForYearTH(this.year);
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    this.filterMonth = courantMonth;

    await this.getFilialeByUser();
    await this.getSettingFilialeTh();
  }
};
</script>
<style lang="scss">
.flex-row-radio-sci-mensuel {
  display: flex;
  align-items: center;
  .radio-btn {
    .v-label {
      top: 2px !important;
      font-size: 12px !important;
    }
  }
}
.table-fac-sci-mensuel-error,
.table-fac-sci-mensuel-proforma {
  .v-data-table-header {
    tr {
      background-color: #fff;
      text-align: center !important;
      th {
        border: 1px solid #e0e0e0;
        font-size: 13px !important;
        text-align: center !important;
        span {
          font-size: 12px;
          color: #2a2a2a;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
  }
}
.table-fac-sci-mensuel-proforma {
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
.table-master {
  table,
  td,
  tr,
  th {
    border: 1px solid #e0e0e0;
  }
}
.title-list-sci-mensuel {
  border: 1px solid #e0e0e0;
  margin-bottom: 0px !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 2;
}
.content-list-groupe {
  padding: 0px 60px;
}
.titleFiche {
  color: #704ad1;
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif;
}
.table-fac-sci-mensuel-error,
.table-fac-sci-mensuel-proforma {
  padding-top: 10px;
  thead {
    tr {
      background-color: #fff;
      text-align: center !important;
      th {
        border: 1px solid #e0e0e0;
        font-size: 13px !important;
        text-align: center !important;
        height: 35px !important;
        span {
          font-size: 12px;
          color: #2a2a2a;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #e0e0e0;
      background-color: #fff;
      text-align: center !important;
      td {
        border: 1px solid #e0e0e0;
        font-size: 13px !important;
        text-align: center !important;
        height: 35px !important;
      }
      &:hover {
        border: 1px solid #e0e0e0 !important;
        background-color: #fff !important;
        text-align: center !important;
      }
    }
  }
}
</style>
